h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "'Montserrat', sans-serif";
}

.react-reveal {
  overflow: auto;
}

.alice-carousel__dots {
  margin: 0px;
}

.alice-carousel__prev-btn {
  padding-bottom: 0px;
  padding-top: 0px;
}

.alice-carousel__next-btn {
  padding-bottom: 0px;
  padding-top: 0px;
  text-align: left;
}

video {
  position: fixed;
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}

.section {
  position: relative;
  width: 100%;
  min-height: 1100px;
  display: flex;
  justify-content: center;
}

.homeSection {
  position: relative;
  width: 100%;
  min-height: 1200px;
}

.footerContent:hover {
  cursor: pointer;
  text-decoration: underline;
}
